import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Backdrop, Box, Modal as MuiModal, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import './Modal.css'; // 추가된 CSS 파일

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
  bgcolor: 'background.paper',
};

const MotionBox = styled(motion.div)({});

const modalVariants = {
  hidden: { opacity: 0, filter: 'blur(10px)' },
  visible: { opacity: 1, filter: 'blur(0px)' },
  exit: { opacity: 0, filter: 'blur(10px)' }
};

const Modal = ({ open, handleClose, content, scrollPosition }) => {
  useEffect(() => {
    if (open) {
      console.log(`Modal Open - Scroll Position: ${scrollPosition}`); // 디버그 로그
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = '100%';
    } else {
      const scrollPos = parseInt(document.body.style.top || '0') * -1;
      console.log(`Modal Close - Restoring Scroll Position: ${scrollPos}`); // 디버그 로그
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, scrollPos);
    }

    return () => {
      console.log(`Cleanup - Restoring Scroll Position: ${scrollPosition}`); // 디버그 로그
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, scrollPosition);
    };
  }, [open, scrollPosition]);

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <MotionBox
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
        style={style}
        className="modal-container"
      >
        <Box className="modal-content">
          <Typography variant="h6" component="h2" className="modal-title">
            {content.title}
          </Typography>
          <Typography sx={{ mt: 2 }} className="modal-body" dangerouslySetInnerHTML={{ __html: content.body }}></Typography>
          <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained" className="modal-close-button">
            Close
          </Button>
        </Box>
      </MotionBox>
    </MuiModal>
  );
};

export default Modal;
