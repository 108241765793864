import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FixedButtons.css';

const FixedButtons = () => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [scrollCount, setScrollCount] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrollCount(prevCount => {
                    const newCount = prevCount + 1;
                    if (newCount >= 2) {
                        setIsVisible(true);
                    }
                    return newCount;
                });
            } else {
                setScrollCount(0);
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const goToContact = () => {
        navigate('/inquiries');
    };

    const handleMouseMove = (e) => {
        const btnSpan = e.currentTarget.querySelector('.btnSpan');
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        btnSpan.style.top = `${y}px`;
        btnSpan.style.left = `${x}px`;
    };

    return (
        <div className={`fixed-buttons ${isVisible ? 'visible' : 'hidden'}`}>
            <button className="btn toContact" onClick={goToContact} onMouseMove={handleMouseMove}>
                Contact <span className="btnSpan"></span>
            </button>
            <button className="btn toTop" onClick={scrollToTop} onMouseMove={handleMouseMove}>
                Top <span className="btnSpan"></span>
            </button>
        </div>
    );
};

export default FixedButtons;
