import React, { useState, useEffect } from 'react';
import './LoadingSVG.css';

const LoadingSVG = () => {
    const [isMobile, setIsMobile] = useState(false);

    // 뷰포트 크기를 감지하는 로직
    const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 768); // 모바일 기준을 768px 이하로 설정
    };

    useEffect(() => {
        // 컴포넌트가 마운트될 때 뷰포트 크기를 확인
        checkIsMobile();

        // 리사이즈 이벤트 리스너 추가
        window.addEventListener('resize', checkIsMobile);

        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    // 모바일에서는 로딩 화면을 표시하지 않음
    if (isMobile) {
        return null;
    }

    return (
        <div className="loading-svg">
            <div className='svg_wrap'>
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 728.5 168.69">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="cls-1" d="M149.14,116.03v2.38c0,27.16-24.54,49.55-54.55,49.55H21.26c-2.13,0-3.85-1.72-3.85-3.85v-25.65c0-2.13,1.72-3.85,3.85-3.85h73.33c10.24,0,18.82-7.38,18.82-16.2v-2.38c0-9.05-8.57-16.2-18.82-16.2h-39.54C25.03,99.83.5,77.44.5,50.28S25.03.5,55.05.5h75.36c2.13,0,3.85,1.72,3.85,3.85v25.65c0,2.13-1.72,3.85-3.85,3.85H55.05c-10.48,0-18.82,7.38-18.82,16.44s8.34,16.2,18.82,16.2h39.54c30.01,0,54.55,22.39,54.55,49.55Z"/>
                        <path className="cls-1" d="M293.27,167.96h-29.08c-1.69,0-3.19-1.11-3.68-2.72l-10.6-34.67-15.01-49.31h-.24l-3.58-11.92c-1.09-3.62-6.2-3.67-7.35-.07l-5.51,17.23-16.91,53.83h.24l-7.73,24.92c-.5,1.61-1.99,2.71-3.68,2.71h-29.3c-2.6,0-4.46-2.53-3.67-5.01l20.09-63.35L207.61,4.37c.51-1.6,1.99-2.68,3.67-2.68h32.47c1.68,0,3.17,1.09,3.67,2.7l49.52,158.57c.77,2.48-1.08,5-3.67,5Z"/>
                        <path className="cls-1" d="M428.02,5.54v25.18c0,2.13-1.72,3.85-3.85,3.85h-69.48c-2.13,0-3.85,1.72-3.85,3.85v125.69c0,2.13-1.72,3.85-3.85,3.85h-28.03c-2.13,0-3.85-1.72-3.85-3.85V5.54c0-2.13,1.72-3.85,3.85-3.85h105.21c2.13,0,3.85,1.72,3.85,3.85ZM381.45,100.75v-25.18c0-2.13,1.72-3.85,3.85-3.85h32.56c2.13,0,3.85,1.72,3.85,3.85v25.18c0,2.13-1.72,3.85-3.85,3.85h-32.56c-2.13,0-3.85-1.72-3.85-3.85Z"/>
                        <path className="cls-1" d="M459.3,30.72V5.54c0-2.13,1.72-3.85,3.85-3.85h105.21c2.13,0,3.85,1.72,3.85,3.85v25.18c0,2.13-1.72,3.85-3.85,3.85h-105.21c-2.13,0-3.85-1.72-3.85-3.85ZM461.18,100.75v-25.18c0-2.13,1.67-3.85,3.72-3.85h101.71c2.05,0,3.72,1.72,3.72,3.85v25.18c0,2.13-1.67,3.85-3.72,3.85h-101.71c-2.05,0-3.72-1.72-3.72-3.85ZM572.2,139.17v25.17c0,2.13-1.72,3.85-3.85,3.85h-105.21c-2.13,0-3.85-1.72-3.85-3.85v-29.02h109.06c2.13,0,3.85,1.72,3.85,3.85Z"/>
                        <path className="cls-1" d="M728,58.38v53.12c0,31.2-27.63,56.69-61.46,56.69h-66.42c-2.13,0-3.85-1.72-3.85-3.85v-28.07h-.24v-.95h70.51c14.29,0,25.73-10.72,25.73-23.82v-53.12c0-13.1-11.43-23.82-25.73-23.82h-66.42c-2.13,0-3.85-1.72-3.85-3.85V5.54c0-2.13,1.72-3.85,3.85-3.85h66.42c33.83,0,61.46,25.49,61.46,56.69Z"/>
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default LoadingSVG;
