import React, { createContext, useContext } from 'react';

// Context 생성
const TextContext = createContext();

// Hook을 통해 쉽게 Context를 사용할 수 있게 함
export const useText = () => {
    const context = useContext(TextContext);
    if (!context) {
        throw new Error('useText must be used within a TextProvider');
    }
    return context.wrapText;
};

// Provider 컴포넌트
const TextProvider = ({ children }) => {
    // 텍스트가 영어 또는 숫자인지 판별하고 클래스를 추가하는 함수
    const wrapText = (text) => {
        const isEnglishOrNumeric = (text) => {
            const regex = /^[A-Za-z0-9\s]*$/;
            return regex.test(text);
        };

        const className = isEnglishOrNumeric(text) ? 'eng' : '';
        return <span className={className}>{text}</span>;
    };

    return (
        <TextContext.Provider value={{ wrapText }}>
            {children}
        </TextContext.Provider>
    );
};

export default TextProvider;
