import React, { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TextProvider from './contexts/TextContext';
import FixedButtons from './components/FixedButtons';
import { AnimatePresence, motion } from 'framer-motion';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Modal from './components/Modal';
import CrumbleMenu from './components/CrumbleMenu';
import LoadingSVG from './components/LoadingSVG';
import './App.css';
import seo from './components/seo/seo'

// Lazy Loading을 통해 컴포넌트를 지연 로드
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const CompanyOverview = lazy(() => import('./pages/CompanyOverview'));
const VisionMission = lazy(() => import('./pages/VisionMission'));
const Location = lazy(() => import('./pages/Location'));
const Services = lazy(() => import('./pages/Services'));
const BusinessBackground = lazy(() => import('./pages/BusinessBackground'));
const Welcome = lazy(() => import('./pages/Welcome'));
const BusinessOverview = lazy(() => import('./pages/BusinessOverview'));
const SafeDFeatures = lazy(() => import('./pages/SafeDFeatures'));
const Recruitment = lazy(() => import('./pages/Recruitment'));
const RecruitmentGuide = lazy(() => import('./pages/RecruitmentGuide'));
const RecruitmentInfo = lazy(() => import('./pages/RecruitmentInfo'));
const Contact = lazy(() => import('./pages/Contact'));
const Notices = lazy(() => import('./pages/Notices'));
const PressReleases = lazy(() => import('./pages/PressReleases'));
const Inquiries = lazy(() => import('./pages/Inquiries'));
const FAQ = lazy(() => import('./pages/FAQ'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const SubHeroSection = lazy(() => import('./components/SubHeroSection'));



const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  );
};

const Main = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', body: '' });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const firstVisit = localStorage.getItem('firstVisit') === null;
    if (firstVisit) {
      localStorage.setItem('firstVisit', 'false');
      setTimeout(() => {
        setShowLoader(false);
      }, 5000); // 2초 동안 로딩 화면 표시
    } else {
      setShowLoader(false);
    }
  }, []);

  
  useEffect(() => {
    // 경로에 따라 body에 클래스를 추가하는 코드
    const pathClassMap = {
      '/about': 'about-us',
      '/company-overview': 'about-us',
      '/vision-mission': 'about-us',
      '/location': 'about-us',
      '/services': 'services',
      '/business-background': 'services',
      '/welcome': 'services',
      '/business-overview': 'services',
      '/safed-features': 'services',
      '/recruitment': 'recruitment',
      '/recruitment-guide': 'recruitment',
      '/recruitment-info': 'recruitment',
      '/contact': 'customer-support',
      '/notices': 'customer-support',
      '/press-releases': 'customer-support',
      '/inquiries': 'customer-support',
      '/faq': 'customer-support'
    };

    const currentPath = location.pathname;
    const bodyClass = pathClassMap[currentPath] || '';
    document.body.className = bodyClass;

    return () => {
      document.body.className = '';
    };
  }, [location.pathname]);

  const handleOpen = (title, body) => {
    const scrollPos = window.scrollY; // 스크롤 위치 저장
    setScrollPosition(scrollPos);
    setModalContent({ title, body });
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPos}px`;
    document.body.style.width = '100%';
    setOpen(true);
    console.log(`Modal Open - Scroll Position: ${scrollPos}`); // 디버그 로그
  };

  const handleClose = () => {
    setOpen(false);
    const scrollPos = parseInt(document.body.style.top || '0') * -1;
    console.log(`Modal Close - Restoring Scroll Position: ${scrollPos}`); // 디버그 로그
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    window.scrollTo(0, scrollPos); // 저장된 위치로 스크롤 복구
  };

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50
    },
    in: {
      opacity: 1,
      y: 0
    },
    out: {
      opacity: 0,
      y: -50
    }
  };

  const pageTransition = {
    type: "spring",
    stiffness: 80,
    damping: 15
  };
  const isMainPage = location.pathname === '/';

  return (
    <TextProvider>
      <div className="app-container">
        {showLoader ? (
          <LoadingSVG />
        ) : (
          <>
            <Navigation />
            <div className="contents">
              <AnimatePresence mode="wait">
                <Suspense fallback={<LoadingSVG />}>
                  <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<MotionComponent variants={pageVariants} transition={pageTransition}><Home /></MotionComponent>} />
                    <Route path="/about" element={<><SubHeroSection /><About /></>} />
                    <Route path="/company-overview" element={<><SubHeroSection /><CompanyOverview /></>} />
                    <Route path="/vision-mission" element={<><SubHeroSection /><VisionMission /></>} />
                    <Route path="/location" element={<><SubHeroSection /><Location /></>} />
                    <Route path="/services" element={<><SubHeroSection /><Services /></>} />
                    <Route path="/business-background" element={<><SubHeroSection /><BusinessBackground /></>} />
                    <Route path="/welcome" element={<><SubHeroSection /><Welcome /></>} />
                    <Route path="/business-overview" element={<><SubHeroSection /><BusinessOverview /></>} />
                    <Route path="/safed-features" element={<><SubHeroSection /><SafeDFeatures /></>} />
                    <Route path="/recruitment" element={<><SubHeroSection /><Recruitment /></>} />
                    <Route path="/recruitment-guide" element={<><SubHeroSection /><RecruitmentGuide /></>} />
                    <Route path="/recruitment-info" element={<><SubHeroSection /><RecruitmentInfo /></>} />
                    <Route path="/contact" element={<><SubHeroSection /><Contact /></>} />
                    <Route path="/notices" element={<><SubHeroSection /><Notices /></>} />
                    <Route path="/press-releases" element={<><SubHeroSection /><PressReleases /></>} />
                    <Route path="/inquiries" element={<><SubHeroSection /><Inquiries /></>} />
                    <Route path="/faq" element={<><SubHeroSection /><FAQ /></>} />
                    <Route path="/privacy-policy" element={<><SubHeroSection /><PrivacyPolicy /></>} />
                    <Route path="/TermsOfService" element={<><SubHeroSection /><TermsOfService /></>} />
                  </Routes>
                </Suspense>
              </AnimatePresence>
            </div>
            {!isMainPage && <FixedButtons />}
            <Footer handleOpen={handleOpen} />
            <Modal open={open} handleClose={handleClose} content={modalContent} scrollPosition={scrollPosition} />
          </>
        )}
      </div>
    </TextProvider>
  );
};

const MotionComponent = ({ children, variants, transition }) => (
  <motion.div
    initial="initial"
    animate="in"
    exit="out"
    variants={variants}
    transition={transition}
  >
    {children}
  </motion.div>
);

export default App;
