import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ 
    title = "SafeD – 스마트안전관리시스템", 
    description = "세이프디(SAFED)는 건설현장, 토목현장, 공장, 플랜트, 소규모 공사, 서비스 회사에서 안전보건관리체계를 효율적으로 구축할 수 있는 솔루션을 개발하는 소프트웨어 개발전문 회사입니다. 또한, 관리감독자, 근로자 등 현장의 생명을 위험요소로 부터 지켜 고객 일상의 풍요로운 삶과 행복한 미래를 함께 만들고 있습니다.", 
    keywords = "세이프디, SafeD, 위험성평가, 스마트위험성평가, 건설안전, 안전관리, 스마트안전관리, 안전관리시스템, 안전관리솔루션", 
    url = "https://safed.kr",
    image = "https://safed.kr/oglogo.png"
}) => {
    return (
        <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        
        {/* Open Graph 태그 */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />
        
        {/* Twitter Card 태그 (선택 사항) */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
    </Helmet>
    );
};

export default SEO;
