import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './Navigation.css';
import logo from '../img/logo.png';
import whiteLogo from '../img/white_logo.png'; // white 버전 로고 import

const Navigation = () => {
    const location = useLocation();
    const isMainPage = location.pathname === '/';

    const [isScrolled, setIsScrolled] = useState(false);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [hoveredMenu, setHoveredMenu] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navVariants = {
        hidden: { opacity: 0, y: -30 ,transition: { ease: 'easeIn', duration: 0.7 }},

        visible: { opacity: 1, y: 0, transition: { type: 'spring',ease: 'easeInOut', damping: 50 } },
    };

    const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 500px, rgba(0,0,0,1) 500px, rgba(0,0,0,1) 500px)`;
    const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 500px)`;

    const overlayVariants = {
        hidden: {
            opacity: 0,
            WebkitMaskImage: hiddenMask,
            maskImage: hiddenMask,
            transition: {
                duration: 0.7, // 여기에 사라질 때의 duration 값을 늘립니다.
                when: 'afterChildren',
                staggerChildren: 0.15,
                staggerDirection: -1
            }
        },
        visible: {
            opacity: 1,
            WebkitMaskImage: visibleMask,
            maskImage: visibleMask,
            transition: {
                duration: 0.7,
                when: 'beforeChildren',
                staggerChildren: 0.15
            }
        }
    };

    const columnVariants = {
        hidden: {
            opacity: 0,
            y: 20,
            transition: { duration: 0.5 }
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5 }
        }
    };

    const submenuVariants = {
        hidden: {
            y: -20,
            opacity: 0,
            filter: 'blur(5px)',
            transition: { ease: 'easeIn', duration: 0.22 }
        },
        visible: {
            y: 0,
            opacity: 1,
            filter: 'blur(0px)',
            transition: { type: 'spring', duration: 0.7 }
        },
        exit: {
            y: -20,
            opacity: 0,
            filter: 'blur(5px)',
            transition: { ease: 'easeOut', duration: 0.22 }
        }
    };

    const toggleOverlay = () => {
        setIsOverlayOpen(!isOverlayOpen);
    };

    return (
        <>
            <motion.div
                className={`navigation_wrap ${isScrolled ? 'scrolled' : ''} ${isMainPage ? 'main-page' : ''}`}
                initial="hidden"
                animate="visible"
                variants={navVariants}
            >
                <nav className={`navigation`}>
                    <h1>
                        <a href="/">
                            <img src={isMainPage && !isScrolled ? whiteLogo : logo} alt="logo" />
                        </a>
                    </h1>
                    <ul>
                        <li
                            onMouseEnter={() => setHoveredMenu('about')}
                            onMouseLeave={() => setHoveredMenu(null)}
                        >
                            <Link to="/company-overview" className={`${isMainPage && !isScrolled ? 'main-page-link' : ''}`}>About Us</Link>
                            <AnimatePresence>
                                {hoveredMenu === 'about' && (
                                    <motion.div
                                        className="submenu"
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        variants={submenuVariants}
                                    >
                                        <Link to="/company-overview">회사개요</Link>
                                        <Link to="/welcome">인사말</Link>
                                        <Link to="/vision-mission">비전 및 미션</Link>
                                        <Link to="/location">오시는 길</Link>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </li>
                        <li
                            onMouseEnter={() => setHoveredMenu('services')}
                            onMouseLeave={() => setHoveredMenu(null)}
                        >
                            <Link to="/business-background" className={`${isMainPage && !isScrolled ? 'main-page-link' : ''}`}>Our Business</Link>
                            <AnimatePresence>
                                {hoveredMenu === 'services' && (
                                    <motion.div
                                        className="submenu"
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        variants={submenuVariants}
                                    >
                                        <Link to="/business-background">사업배경</Link>
                                        <Link to="/safed-features">SafeD Development</Link>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </li>
                        <li
                            onMouseEnter={() => setHoveredMenu('recruitment')}
                            onMouseLeave={() => setHoveredMenu(null)}
                        >
                            <Link to="/recruitment-guide" className={`${isMainPage && !isScrolled ? 'main-page-link' : ''}`}>Careers</Link>
                            <AnimatePresence>
                                {hoveredMenu === 'recruitment' && (
                                    <motion.div
                                        className="submenu"
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        variants={submenuVariants}
                                    >
                                        <Link to="/recruitment-guide">채용안내</Link>
                                        <Link to="/recruitment-info">채용정보</Link>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </li>
                        <li
                            onMouseEnter={() => setHoveredMenu('contact')}
                            onMouseLeave={() => setHoveredMenu(null)}
                        >
                            <Link to="/notices" className={`${isMainPage && !isScrolled ? 'main-page-link' : ''}`}>Support</Link>
                            <AnimatePresence>
                                {hoveredMenu === 'contact' && (
                                    <motion.div
                                        className="submenu"
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        variants={submenuVariants}
                                    >
                                        <Link to="/notices">공지사항</Link>
                                        <Link to="/press-releases">보도자료</Link>
                                        <Link to="/inquiries">상담문의</Link>
                                        {/* <Link to="/faq">FAQ</Link> */}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </li>
                    </ul>
                    <div className="right">
                        <Link to="/inquiries" className="inquiry-button">도입문의<i className='xi-magnifier'></i></Link>
                        <div className="ham" onClick={toggleOverlay}>
                            <span className="eng menu_text">MENU</span><i className="xi-bars"></i>
                        </div>
                    </div>
                </nav>
            </motion.div>

            <AnimatePresence>
                {isOverlayOpen && (
                    <motion.div
                        className="overlay"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={overlayVariants}
                        onClick={toggleOverlay}
                    >
                        <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                            <button className="close-btn" onClick={toggleOverlay}>
                                <svg width="24" height="24" viewBox="0 0 24 24" className="close-icon">
                                    <line x1="4" y1="4" x2="20" y2="20" />
                                    <line x1="20" y1="4" x2="4" y2="20" />
                                </svg>
                            </button>
                            <motion.div className="overlay-links" variants={overlayVariants}>
                                <motion.div className="overlay-column" variants={columnVariants}>
                                    <h3>회사소개</h3>
                                    <ul>
                                        <li><Link to="/company-overview" onClick={toggleOverlay}>회사개요</Link></li>
                                        <li><Link to="/welcome" onClick={toggleOverlay}>인사말</Link></li>
                                        <li><Link to="/vision-mission" onClick={toggleOverlay}>비전 및 미션</Link></li>
                                        <li><Link to="/location" onClick={toggleOverlay}>오시는 길</Link></li>
                                    </ul>
                                </motion.div>
                                <motion.div className="overlay-column" variants={columnVariants}>
                                    <h3>사업소개</h3>
                                    <ul>
                                        <li><Link to="/business-background" onClick={toggleOverlay}>사업배경</Link></li>
                                        <li><Link to="/safed-features" onClick={toggleOverlay}>SafeD Development</Link></li>
                                    </ul>
                                </motion.div>
                                <motion.div className="overlay-column" variants={columnVariants}>
                                    <h3>채용정보</h3>
                                    <ul>
                                        <li><Link to="/recruitment-guide" onClick={toggleOverlay}>채용안내</Link></li>
                                        <li><Link to="/recruitment-info" onClick={toggleOverlay}>채용정보</Link></li>
                                    </ul>
                                </motion.div>
                                <motion.div className="overlay-column" variants={columnVariants}>
                                    <h3>고객지원</h3>
                                    <ul>
                                        <li><Link to="/notices" onClick={toggleOverlay}>공지사항</Link></li>
                                        <li><Link to="/press-releases" onClick={toggleOverlay}>보도자료</Link></li>
                                        <li><Link to="/inquiries" onClick={toggleOverlay}>상담문의</Link></li>
                                        {/* <li><Link to="/faq" onClick={toggleOverlay}>FAQ</Link></li> */}
                                    </ul>
                                </motion.div>
                            </motion.div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default Navigation;
